export function getCookie<T>(name: string, defaultValue: T): T {
    const nameEQ = name + "=";
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.indexOf(nameEQ) === 0) {
            const cookieStr = cookie.substring(nameEQ.length, cookie.length);
            try {
                const decoded = decodeURIComponent(cookieStr);
                return JSON.parse(decoded);
            } catch (_e) {
                return cookieStr as T;
            }
        }
    }
    return defaultValue;
}

export function setCookie<T>(cname: string, val: T, daysToExpiry = 1000) {
    const d = new Date();
    d.setTime(d.getTime() + daysToExpiry * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie =
        cname +
        "=" +
        encodeURIComponent(JSON.stringify(val)) +
        ";" +
        expires +
        ";path=/";
}
